define("discourse/plugins/discourse-category-experts/discourse/components/category-experts-approve-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _ajax, _ajaxError, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.setPostCategoryExpertAttributes = setPostCategoryExpertAttributes;
  class CategoryExpertsApproveButton extends _component.default {
    static shouldRender(args) {
      return !args.post.category_expert_approved_group && args.post.needs_category_expert_approval;
    }
    // TODO (glimmer-post-menu): Remove this static method and move the code into the button action after the widget code is removed
    static approveCategoryExpertPost(post, appEvents) {
      setPostCategoryExpertAttributes(post, appEvents, {
        approved: true
      });
    }
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.inject]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    get showLabel() {
      return this.args.showLabel ?? true;
    }
    approveCategoryExpertPost() {
      CategoryExpertsApproveButton.approveCategoryExpertPost(this.args.post, this.appEvents);
    }
    static #_2 = (() => dt7948.n(this.prototype, "approveCategoryExpertPost", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="approve-category-expert-post"
          ...attributes
          @action={{this.approveCategoryExpertPost}}
          @icon="thumbs-up"
          @label={{if this.showLabel "category_experts.approve"}}
          @title="category_experts.approve"
        />
      
    */
    {
      "id": "3VxlkdCy",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"approve-category-expert-post\"],[17,1]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[30,0,[\"approveCategoryExpertPost\"]],\"thumbs-up\",[52,[30,0,[\"showLabel\"]],\"category_experts.approve\"],\"category_experts.approve\"]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-category-experts/discourse/components/category-experts-approve-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryExpertsApproveButton;
  async function setPostCategoryExpertAttributes(post, appEvents) {
    let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
      approved: true
    };
    try {
      const response = await (0, _ajax.ajax)(`/category-experts/${opts.approved ? "approve" : "unapprove"}`, {
        type: "POST",
        data: {
          post_id: post.id
        }
      });
      post.setProperties({
        needs_category_expert_approval: !opts.approved,
        category_expert_approved_group: opts.approved ? response.group_name : false
      });
      post.topic.setProperties({
        needs_category_expert_post_approval: response.topic_needs_category_expert_approval,
        expert_post_group_names: response.topic_expert_post_group_names
      });
      appEvents.trigger("post-stream:refresh", {
        id: post.id
      });
    } catch (error) {
      (0, _ajaxError.popupAjaxError)(error);
    }
  }
});